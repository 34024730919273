import UAParser from 'ua-parser-js';

const getUAParser = (userAgent?: string): UAParser => {
  const ua = typeof window !== 'undefined' ? userAgent || window.navigator.userAgent : userAgent || '';
  const parser = new UAParser(ua);
  return parser;
};

export const isCrushAppIOS = (userAgent?: string): boolean => {
  console.log('getUAParser');
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();
  console.log(ua);
  return ua.startsWith('Crush App iOS');
};

export const isCrushAppAndroid = (userAgent?: string): boolean => {
  const parser = getUAParser(userAgent);
  const ua = parser.getUA();
  return ua.startsWith('Crush App Android');
};

export const isCrushAppMobile = (userAgent?: string): boolean => {
  return isCrushAppIOS(userAgent) || isCrushAppAndroid(userAgent);
};
