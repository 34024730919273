import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import { UpdateUserBody } from 'api/user/types';
import { getStorageItem } from 'utils/storage';
import { auth } from 'lib/firebase/firebase-config';
import { Modal } from 'components/modals/Modal';
import { isCrushAppMobile } from 'utils/userAgentCheck';
import { genders, orientation } from 'utils/hardcoded-data';
import { userDetailsSchema } from 'utils/yup';
import { useAuthStore } from 'store/auth';
import { getFormikError } from 'utils';
import { Checkbox } from './Checkbox';
import { updateMe } from 'api/user';
import { Select } from './Select';
import { Input } from './Input';

type Props = {
  onSuccess: () => void;
};

export const UserDetailsForm = ({ onSuccess }: Props) => {
  const { push } = useRouter();
  const { mutate, isLoading } = useMutation(updateMe, {
    onSuccess: () => {
      push('/');
      onSuccess();
    }
  });
  const { user } = useAuthStore();

  const authUser = auth.currentUser;

  const formik = useFormik({
    initialValues: {
      name: (authUser?.displayName || '').split(' ')[0],
      gender: 'Male',
      orientation: 'Women',
      accept_tos_18: false
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      const refer = getStorageItem('refer');

      const body: UpdateUserBody = {
        custom_attributes: {
          name: values.name,
          gender: values.gender,
          orientation: values.orientation,
          accept_tos_18: values.accept_tos_18,
          picture: user?.custom_attributes.picture || authUser?.photoURL || '',
          refer_source: refer?.source || null,
          refer_id: refer?.id || null,
          refer_domain: refer?.domain || null,
          refer_domain_initial: refer?.domain_initial || null,
          app_mode: isCrushAppMobile() ? 'crush:sfw' : 'crush:nsfw',
          default_language: navigator.language
        }
      };

      mutate(body);
    }
  });

  useEffect(() => {
    if (authUser?.displayName) formik.setFieldValue('name', (authUser.displayName || '').split(' ')[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <Modal nonDismissable>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1.5 text-center">
          <h1 className="text-2xl">Let your Crush know you better ❤️</h1>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
          <Input label="What should we call you?" name="name" formik={formik} />

          <Select
            label="You are"
            placeholder="Select your gender"
            name="gender"
            options={genders}
            value={genders.find((g) => g.value === formik.values.gender) || ''}
            formik={formik}
            onChange={(value: any) => formik.setFieldValue('gender', value.value)}
          />
          <Select
            label="Interested in"
            placeholder="Select your interest"
            name="orientation"
            options={orientation}
            value={orientation.find((g) => g.value === formik.values.orientation) || ''}
            formik={formik}
            onChange={(value: any) => formik.setFieldValue('orientation', value.value)}
          />
          <Checkbox
            isChecked={formik.values.accept_tos_18}
            toggle={() => {
              formik.setFieldValue('accept_tos_18', !formik.values.accept_tos_18);
            }}
            label="I confirm that I have read and accept the Terms of Service and affirm that I am 18 years of age or older."
          />
          <p className="error-msg">{getFormikError(formik, 'accept_tos_18')}</p>
          <button type="submit" className="btn contained primary" disabled={isLoading}>
            Continue
          </button>
        </form>
      </div>
    </Modal>
  );
};
