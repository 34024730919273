import Link from 'next/link';
import React from 'react';
import { useAuthStore } from 'store/auth';
import SilverCoin from 'public/images/silverCoin.png';
import GoldCoin from 'public/images/goldCoin.png';

export const CoinBalance = () => {
  const { user } = useAuthStore();
  const CoinImage = user?.is_premium ? GoldCoin : SilverCoin;

  return (
    <div className="flex items-center gap-4">
      <Link
        prefetch={false}
        href={user?.is_premium ? '/credits' : '/subscriptions'}
        className="flex items-center gap-1 bg-brand px-2 py-0.5 rounded-full"
      >
        <div className="w-5 h-5 rounded-full">
          <img src={CoinImage.src} alt="Coin" className="w-full h-full object-cover rounded-full" />
        </div>
        {user?.id ? (
          <span className="cy-coin-balance font-semibold">{user?.credit_balance || 0}</span>
        ) : (
          <span className="font-medium">Get 50 Free</span>
        )}
      </Link>
    </div>
  );
};
