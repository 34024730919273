import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const config = {
  apiKey: process.env.NEXT_PUBLIC_BUGSANG_KEY || '',
  releaseStage: process.env.NEXT_PUBLIC_STAGE_RELEASE
};

typeof window !== 'undefined'
  ? Bugsnag.start({ ...config, plugins: [new BugsnagPluginReact()] })
  : Bugsnag.start(config);

export default Bugsnag;
