import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithCustomToken
} from 'firebase/auth';

import { auth } from 'lib/firebase/firebase-config';
import { useAuthStore } from 'store/auth';
import { RegisterBody, VerifyOTPPayload } from './types';
import { reportBug } from 'utils';
import apiCall from 'api';
import { showToast } from 'components/ui/CustomToast';
import { isCrushAppMobile, isCrushAppIOS, isCrushAppAndroid } from 'utils/userAgentCheck';

export const register = async ({ email, password }: RegisterBody) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const login = async ({ email, password }: RegisterBody) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const sendMagicLink = async (email: string) => {
  const auth = getAuth();

  try {
    await sendSignInLinkToEmail(auth, email, {
      url: `${window?.location.origin}?email=${email}`,
      handleCodeInApp: true
    });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const signInWithMagicLink = async (email: string) => {
  const auth = getAuth();
  const emailLink = window.location.href;

  try {
    if (isSignInWithEmailLink(auth, emailLink)) {
      return await signInWithEmailLink(auth, email, emailLink);
    } else {
      showToast({ message: 'Invalid link!', type: 'error' });
    }
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const googleLogin = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, provider);
    return res;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const appleLogin = async () => {
  try {
    const provider = new OAuthProvider('apple.com');
    const res = await signInWithPopup(auth, provider);
    return res;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const logout = async (redirect?: string) => {
  if (redirect) {
    const { setLogoutRedirect } = useAuthStore.getState();
    setLogoutRedirect(redirect);
  }

  await auth.signOut();
  // ** Mobile check **
  const isAppIOS = isCrushAppIOS();
  const isAppMobile = isCrushAppMobile();

  if (isAppMobile && typeof window !== 'undefined' && window.crush) {
    // check if this is mobile
    console.log(`🔥 Signout isCrushAppIOS: ${isAppIOS} isCrushAppMobile: ${isAppMobile}`);
    //window.crush.signout();
    window.crush.signin();
  } else {
    console.log('❗️ window.crush is undefined');
  }
};

export const changePassword = async ({
  currentPassword,
  newPassword
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  try {
    const user = auth.currentUser;

    const email = useAuthStore.getState().user?.email;
    if (!user || !email) return;

    const credential = EmailAuthProvider.credential(email, currentPassword);
    await reauthenticateWithCredential(user, credential);

    await updatePassword(user, newPassword);

    showToast({ message: 'Password updated successfully!', type: 'success' });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const sendResetPasswordEmail = async () => {
  try {
    const email = useAuthStore.getState().user?.email;

    if (email) {
      await sendPasswordResetEmail(auth, email, {
        url: `${window.location.origin}/reset-password`,
        handleCodeInApp: true
      });

      showToast({ message: `Password reset email is sent to ${email}`, type: 'success' });
    }
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const setUserPassword = async (password: string) => {
  try {
    const user = auth.currentUser;

    if (!user) return;

    await updatePassword(user, password);

    showToast({ message: 'Password set successfully!', type: 'success' });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const requestOTP = async (email: string) => {
  try {
    const request = await apiCall(false);
    await request({ url: `users/requestOTP`, method: 'POST', data: { email } });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const verifyOTP = async (payload: VerifyOTPPayload) => {
  try {
    const request = await apiCall(false);
    const { data: verifyResponse } = await request<{ data: { token: string } }>({
      url: `users/verifyOTP`,
      method: 'POST',
      data: payload
    });

    const token = verifyResponse?.data?.token;

    if (token) {
      const auth = getAuth();
      await signInWithCustomToken(auth, token);
    }
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw new Error(error);
  }
};

export const customTokenSignIn = async (token: string) => {
  try {
    const auth = getAuth();

    console.log('CUSTOM TOKEN SIGN IN: ', token);

    await signInWithCustomToken(auth, token);
  } catch (error: any) {
    console.log('CUSTOM TOKEN SIGN IN ERROR: ', error);
    reportBug({ msg: error?.data?.message, error });
  }
};
