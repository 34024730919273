import apiCall, { baseURL } from 'api';
import {
  ConversationWithLastMessage,
  ConversationWithToken,
  GetConversationMessagesPayload,
  MessageInConversation,
  Attachment,
  RequestPhotoResponse,
  SendTextMessagePayload,
  SurpriseMessagePayload,
  RequestPhotoPayload,
  FetchBotGalleryPayload,
  FetchBotGalleryResponse,
  ContinueConversationPayload,
  PhotoActionPayload,
  ConversationActionPayload,
  UpdateCustomInstructionPayload
} from './types';
import { reactQuery } from 'lib/reactQuery';
import { useAuthStore } from 'store/auth';
import { useModalsStore } from 'store/modals';
import { auth } from 'lib/firebase/firebase-config';
import { reportBug } from 'utils';
import { showToast } from 'components/ui/CustomToast';

export const fetchConversations = async (): Promise<ConversationWithLastMessage[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postConversation = async (account_id?: number): Promise<ConversationWithLastMessage> => {
  try {
    if (!account_id) return Promise.reject();
    const request = await apiCall();
    const { data } = await request({ url: `conversations`, method: 'POST', data: { account_id } });

    reactQuery.refetchQueries(['all-conversations']);
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchSingleConversation = async (account_id: string): Promise<ConversationWithToken> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${+account_id}`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const sendTextMessage = async ({ conversationId, content, bot }: SendTextMessagePayload) => {
  try {
    const { user } = useAuthStore.getState();
    const { setUpsellModal, setCreditsUpsellModal } = useModalsStore.getState();

    if (!user?.is_premium && user?.credit_balance === 0) {
      setUpsellModal({
        image: bot?.profile_image_url || '',
        type: 'out_of_credit',
        showRefreshCountdown: true
      });
      return;
    }

    const request = await apiCall();
    const { data } = await request({
      url: `conversations/${conversationId}/messages`,
      method: 'POST',
      data: { content }
    });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchConversationMessages = async ({
  conversationId,
  before_id
}: GetConversationMessagesPayload): Promise<MessageInConversation[]> => {
  try {
    if (!before_id) return [];
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/${conversationId}/messages`,
      method: 'GET',
      params: { before_id }
    });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const requestPhoto = async ({ conversationId, type }: RequestPhotoPayload) => {
  try {
    const request = await apiCall();
    const data = await request<RequestPhotoResponse>({
      url: `conversations/${conversationId}/photos`,
      method: 'POST',
      data: { request: '', type }
    });

    return data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error, prodToast: true });
    return Promise.reject(error);
  }
};

export const unlockPhoto = async ({ id, image_url }: { id: number; image_url: string }): Promise<Attachment> => {
  const { user } = useAuthStore.getState();
  const { setUpsellModal, setCreditsUpsellModal } = useModalsStore.getState();

  try {
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/photos/${id}/unlock`,
      method: 'POST'
    });

    return data.data;
  } catch (error: any) {
    if (error?.data?.error_code === 'PREMIUM_ONLY') {
      setUpsellModal({ image: image_url, type: 'premium_photo' });
    } else if (error?.data?.error_code === 'NOT_ENOUGH_BALANCE') {
      if (user?.is_premium) {
        setCreditsUpsellModal(image_url || '');
      } else {
        setUpsellModal({ image: image_url, type: 'out_of_credit', showRefreshCountdown: true });
      }
    } else {
      reportBug({ msg: error?.data?.message, error, prodToast: true });
    }
    return Promise.reject(error);
  }
};

export const getVoiceMessage = async (message_id: number): Promise<string> => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const response = await fetch(`${baseURL}conversations/messages/${message_id}/voice`, {
      method: 'POST',
      headers: {
        Authorization: token!
      }
    });

    if (!response.ok) {
      const data = await response.json();
      if (data?.message === 'Voice message already generated') {
        throw new Error(data.message);
      } else if (data?.message.includes('not have enough credits')) {
        throw new Error('Insufficient balance');
      } else if (data?.message.includes('Insufficient balance')) {
        throw new Error('Insufficient balance');
      } else if (data?.message === 'Voice message is being generated') {
        throw new Error('Voice message is being generated');
      } else {
        throw new Error('Failed to generate voice message');
      }
    }

    const { data } = await response.json();
    const { url, new: isNew } = data;

    return isNew ? `${baseURL}${url.replace('/public/v1/', '')}` : url;
  } catch (err: any) {
    if (err.message && err.message !== 'Insufficient balance' && err.message !== 'Voice message is being generated') {
      reportBug({ msg: err?.message, error: err, prodToast: true });
    }
    return Promise.reject(err);
  }
};

export const fetchBotGallery = async ({
  conversationId,
  ...params
}: FetchBotGalleryPayload): Promise<FetchBotGalleryResponse> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/photos`, params });
    return data.data;
  } catch (error: any) {
    return { count: 0, data: [] };
  }
};

export const postSurpriseMessage = async ({ conversationId, type }: SurpriseMessagePayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `conversations/${conversationId}/surprise`,
      method: 'POST',
      data: { type }
    });

    console.log('postSurpriseMessage response: ', data);
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postContinueConversation = async ({ conversationId }: ContinueConversationPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/continue`, method: 'POST' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postStopConversation = async ({ conversationId }: ContinueConversationPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/stop`, method: 'POST' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const postPhotoAction = async ({ photo_id, action }: PhotoActionPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/photos/${photo_id}/${action}`, method: 'POST' });

    return data.data;
  } catch (err: any) {
    reportBug({ msg: err?.data?.message, error: err });
    return Promise.reject(err);
  }
};

export const postConversationAction = async ({ conversationId, action }: ConversationActionPayload) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `conversations/${conversationId}/${action}`, method: 'POST' });

    return data.data;
  } catch (err: any) {
    reportBug({ msg: err?.data?.message, error: err });
    return Promise.reject;
  }
};

export const putCustomInstructions = async ({
  conversationId,
  custom_instructions
}: UpdateCustomInstructionPayload) => {
  try {
    const request = await apiCall();
    await request({
      url: `conversations/${conversationId}/config`,
      method: 'PUT',
      params: { custom_instructions }
    });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};
