import ReactSelect, { Props as SelectProps } from 'react-select';

import reactSelectStylesConfig from 'lib/react-select';
import { getFormikError } from 'utils';

type Props = SelectProps & {
  label?: string;
  formik?: any;
};

export const Select = ({ label, formik, ...rest }: Props) => {
  const error = getFormikError(formik, rest.name);

  return (
    <div className="flex flex-col gap-1">
      {label && <label className="text-sm text-white font-semibold">{label}</label>}
      <ReactSelect styles={reactSelectStylesConfig} {...rest} />
      {error && <p className="text-brand text-sm">{error}</p>}
    </div>
  );
};
