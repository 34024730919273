import { Checkmark } from 'components/icons/Checkmark';

type Props = {
  isChecked: boolean;
  toggle?: () => void;
  label: string;
};

export const Checkbox = ({ isChecked, toggle, label }: Props) => (
  <div className="flex gap-2 items-start">
    <div
      className={`trans border rounded-md flex items-center justify-center w-5 h-5 cursor-pointer text-white ${
        isChecked ? 'border-brand bg-brand' : 'border-brand'
      }`}
      onClick={toggle}
    >
      <Checkmark className={isChecked ? 'opacity-100' : 'opacity-0'} />
    </div>
    <div className="flex-1 -mt-0.5" onClick={toggle}>
      <label className="text-sm text-white cursor-pointer">{label}</label>
    </div>
  </div>
);
